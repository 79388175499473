<template>

  <div class="navbar_custom">
    <b-navbar toggleable="lg" type="light" variant="light" style="background-color: #FFB52E !important">
      <b-navbar-brand href="#">
        <img src="../assets/img.png" alt="Kitten">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav >
          <b-nav-item href="#" to="/refunds">Refunds</b-nav-item>
          <b-nav-item href="#" to="/completed">Completed</b-nav-item>
          <b-nav-item href="#" to="/settings" v-if="getUser.role==='admin'">Settings</b-nav-item>
          <b-nav-item href="#" type="dark" variant="dark" >Disabled</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>{{ getUser.name }}</em>
            </template>
            <b-dropdown-item href="#">Profile</b-dropdown-item>
            <b-dropdown-item href="#" @click="log_out">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
import moment from 'moment'

import { mapGetters, mapActions } from 'vuex'
import axios from "axios";
// import {variables} from "../constants/systemConstants";

export default {
  name: 'TopNav',

  watch: {
    search_: function () {
      this.search()
    },
  },
  data () {
    return {
      toggleSidebar: false,
      search_ : '',
      selected : {},
      search_result : []
    }
  },
  created () {
    console.log(moment())
  },
  computed: {
    ...mapGetters(['getUser'])
  },
  methods:
      {
        ...mapActions(['nullUserObj', 'changeEvent']),

        reset(){
          let app = this
          setTimeout(()=> {
            app.search_result = []
          }, 1000)

        },
        search(){

          // console.log("I am changed")

          if(this.search_ === "")
          {
            this.search_result = []
            return
          }
          let data = {
            search : this.search_
          }

          let app = this
          axios
              .post("/auth/search", data, {  cancelToken: source.token})
              .then((res) => {

                let data = []
                for(let i = 0; i <res.data.events.length; i++ )
                {
                  let event = res.data.events[i]
                  let new_obj =  event.time
                  + " " +  event.customer_name
                      // + " " + event.description
                      // + " " + event.customer_email
                      // + " " + event.color
                      // + " " + event.attachment + " " + event.date_ + " " + event.deposit

                  data.push({refined : new_obj, raw : res.data.events[i]})
                }
                app.search_result = data
              })
              .catch(e => {
                    if (axios.isCancel(e)) {
                      console.debug('Request canceled');
                    } else {
                      console.debug('Request canceled');

                      // app.makeToast(
                      //     variables.danger,
                      //     e.response.data.message,
                      //     variables.server_error
                      // );
                    }
              });
        },

        open_event(obj){
          // console.log(obj, "changed")
          this.changeEvent(obj)
        },
        saveSelectionAndReset()
        {
          // console.log(this.search_result.length)
        },
        log_out () {
          let app = this

          app.$auth.logout({
            params: { token: app.$auth.token() },
            makeRequest: true,
            success: function () {
              app.nullUserObj('')
              location.reload()
            },
            error: function () {
              console.debug('Unable to sing out')
            }
          })
        },
      }
}
</script>

<style scoped>

.navbar_custom{
  color: black !important;
}


.nav-item a{
  color: black !important;
}
</style>
