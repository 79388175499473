<template>
<div class="signin-up-bg">
    <div class="position-relative">
      <div class="login-form">
        <h2 class="text-center mb-4">
          <img class="img_short" src="../../assets/img.png" srcset="../../assets/img.png" alt="CodeLab">
        </h2>
        <h4 class="text-center">Login</h4>
          <div class="form-group">
            <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter email" v-model="email">
          </div>
          <div class="form-group mb-4">
            <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Enter Password" v-model="password">
          </div>

          <div class="form-group clearfix">
            <button type="submit" class="float-right btn btn_login" @click="login_button_click">LOGIN</button>
          </div>
      </div>

    </div>
</div>

</template>

<script>

import { mapActions } from 'vuex'
import { variables } from '../../constants/systemConstants'
import helpermixins from '../../mixins/helperMixins'

export default {
  components: {

  },
  data () {
    return {

      email: '',
      password: '',
        // email: 'admin.alvi@booking.com',
      // password: 'NewAlvi3404',
      notification_tag: 'Login TubeC'
    }
  },

  mounted () {
  },
  mixins: [helpermixins],

  created () {
  },
  methods: {
    ...mapActions(['changeUser']),
    login_button_click () {
      let app = this

      if (this.email === '') {
        app.makeToast(variables.warning, 'Please add your email', app.notification_tag)
        return
      }

      if (!this.validEmail(this.email)) {
        app.makeToast(variables.warning, 'Invalid email', app.notification_tag)
        return
      }

      if (this.password === '') {
        app.makeToast(variables.warning, 'Please enter the password', app.notification_tag)
        return
      }

      this.$auth.login({
        params: {
          email: app.email,
          password: app.password
        },
        success: function () {
          app.$auth.fetch().then(
            function (res) {
              app.changeUser(res.data)
            }
          )
        },
        error: function (resp) {
          app.makeToast(variables.danger, resp.response.data.error, 'Login error')
        },
        rememberMe: true,
        redirect: '/'
      })
    }

  },
  watch: {
  }
}
</script>
