<template>

<div class="center_80">

  <div class="left_30">
    <h3 class="text-center">Filters and search</h3>
    <br>
    <b-form-input v-model="search" placeholder="Search keywords" style="width: 50%" v-on:keyup.enter="getRefunds"></b-form-input>

    <br>
    <br>

    <div class="check_boxes">
      <b-form-checkbox
          id="checkbox-1"
          v-model="time_filter"
          name="today"
          value="today"
          unchecked-value=""
      >
        Today's
      </b-form-checkbox>
    <b-form-checkbox
          id="checkbox-2"
          v-model="time_filter"
          name="yesterday"
          value="yesterday"
          unchecked-value=""
      >
        Yesterday
      </b-form-checkbox>

    <b-form-checkbox
          id="checkbox-3"
          v-model="time_filter"
          name="this_week"
          value="this_week"
          unchecked-value=""
      >
        This week
      </b-form-checkbox>

     <b-form-checkbox
          id="checkbox-4"
          v-model="time_filter"
          name="last_week"
          value="last_week"
          unchecked-value=""
      >
        Last week
      </b-form-checkbox>

     <b-form-checkbox
          id="checkbox-5"
          v-model="time_filter"
          name="this_month"
          value="this_month"
          unchecked-value=""
      >
        This month
      </b-form-checkbox>

     <b-form-checkbox
          id="checkbox-6"
          v-model="time_filter"
          name="last_month"
          value="last_month"
          unchecked-value=""
      >
        Last month
      </b-form-checkbox>

      <b-form-checkbox
          id="checkbox-7"
          v-model="time_filter"
          name="custom"
          value="custom"
          unchecked-value=""
      >
        Custom
      </b-form-checkbox>


      <div style="width: 100%" v-if="time_filter === 'custom'">
        <br>
        <label for="example-datepicker-1">Choose a starting date</label>
        <b-form-datepicker id="example-datepicker-1" v-model="custom_date_start" class="mb-2"></b-form-datepicker>

        <label for="example-datepicker">Choose an end date</label>
        <b-form-datepicker id="example-datepicker" v-model="custom_date_end" class="mb-2"></b-form-datepicker>
        <br>

        <b-button @click="getRefunds">Get data</b-button>
      </div>

    </div>

  </div>
  <div class="right_70">
      <h3 class="text-center">Manage refunds</h3>

      <div class="refund_container">

<!--        <div class="refund" v-for="refund in refunds" v-bind:key="refund._id">{{refund.order_id}}</div>-->

        <div>
          <b-table striped hover :items="items" :fields="fields" :busy="loading">
            <template #table-busy>
              <div class="text-center my-2 text-style">
                <b-spinner class="align-middle">
                </b-spinner>
              </div>
            </template>


<!--            <template #cell(action)="row">-->
<!--              <b-button size="sm" @click="doRefund(row)" class="mr-2">-->
<!--                 Refund-->
<!--              </b-button>-->

<!--              <b-button size="sm" @click="doVoid(row)" class="mr-2">-->
<!--                 Void-->
<!--              </b-button>-->

<!--            </template>-->


          </b-table>
        </div>

      </div>
  </div>
</div>
</template>

<script>
import moment from 'moment'
import {variables} from "../constants/systemConstants";
import axios from "axios";
import helperMixins from "../mixins/helperMixins";
import {mapGetters} from "vuex";
export default {
  name: "Dashboard",

  watch: {
    time_filter : function (value) {
      if(value !== 'custom') {
        this.getRefunds()
      }
    }
  },
  mixins : [helperMixins],
  data () {
    return {
      refunds : [],
      search : '',

      custom_date_start : '',
      custom_date_end : '',
      loading : true,
      time_filter : 'this_month',
      date_format : 'YYYY-MM-DD HH:mm:ss',

      fields: [
        {
          key: 'order_id',
          sortable: true,
          label : 'Order id'
        },
        {
          key: 'refund_amount',
          sortable: true,
          label : 'Refund amount'

        },
        {
          key: 'sub_account',
          label: 'Sub account',
          sortable: true
        },
        {
          key: 'total_amount',
          label: 'Total amount',
          sortable: true
        },
        {
          key: 'domain_name',
          label: 'Domain name',
          sortable: true
        }
        ,
        {
          key: 'date_',
          label: 'Order placed at',
          sortable: true
        },
        {
          key: 'date_refund_created',
          label: 'Refund requested at',
          sortable: true
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },

      ],
      items: []

    }
  },

  beforeMount() {

    this.getRefunds()

  },

  mounted() {
  },
  computed: {
    ...mapGetters(['getUser'])
  },

  methods: {
    getRefunds(){

      let app = this;
      let url = "/auth/get_refunds";


      let first_day = ""
      let last_day = ""

      if(app.time_filter === ""){
        app.time_filter = 'today'
      }

      if(app.time_filter === 'today'){
        first_day = moment().startOf('day')
        last_day = moment().endOf('day')
      }

      if(app.time_filter === 'yesterday'){

        first_day = moment().subtract(1, 'days').startOf('day')
        last_day = moment().subtract(1, 'days').endOf('day')
      }

      if(app.time_filter === 'this_week'){
        first_day = moment().startOf('week')
        last_day = moment().endOf('week')
      }

     if(app.time_filter === 'last_week'){
        first_day = moment().subtract(1, 'weeks').startOf('week')
        last_day = moment().subtract(1, 'weeks').endOf('week')
      }

     if(app.time_filter === 'this_month'){
       first_day = moment().startOf('month')
       last_day = moment().endOf('month')
      }

     if(app.time_filter === 'last_month'){
        first_day = moment().subtract(1, 'months').startOf('month')
        last_day = moment().subtract(1, 'months').endOf('month')
      }

     if(app.time_filter === 'custom'){

       if(app.custom_date_start === '' || app.custom_date_end === '')
       {
         app.makeToast(
             variables.danger,
             "Please select start and end dates",
             variables.danger
         );
         return;
       }

        first_day = moment(app.custom_date_start)
        last_day = moment(app.custom_date_end)

       if(first_day.isAfter(last_day))
       {
         app.makeToast(
             variables.danger,
             "Start date cannot be a date after end date",
             variables.danger
         );
         return
       }
      }

      app.loading = true


      axios
          .post(url, {
            search : app.search,
            is_completed : true,
            from : first_day.format(app.date_format),
            to : last_day.format(app.date_format)
          })
          .then((res) => {
            let refunds = res.data.refunds
            app.items = refunds


            app.loading = false

          })
          .catch(() => {
            app.loading = false
            app.makeToast(
                variables.danger,
                "Unable to load refunds, please contact Hassan",
                variables.danger
            );
          });


    },

    doAction(row){
      console.log(row)
    },

    doRefund(row){
      console.log(row)
    },

    doVoid(row){
      // console.log(row.item._id)
      let url = "/auth/proceed_action";

      let app = this
      axios
          .post(url, {
            _id : row.item._id,
            action : "void"
          })
          .then((res) => {

            app.makeToast(
                variables.success,
                res.data.message,
                variables.success
            );
            this.getRefunds();

          })
          .catch(() => {
            app.loading = false
            app.makeToast(
                variables.danger,
                "Unable to attend your request, please contact Hassan",
                variables.danger
            );
          });

    },



  }
}
</script>

<style scoped>
input {
  outline:none;
}

.refund_container{
  display: flex;
  flex-direction: column;
}


.center_80{
  margin: auto;
  width: 80%;
  margin-top: 1%;
  display: flex;
  flex-direction: row;

}

.left_30{
  padding: 2%;
  width: 30%;
  border-right: 1px solid black;
}

.check_boxes{
  display: flex;
  flex-direction: column;
  width: 80%;
}
.right_70 {
  padding: 2%;
  width: 70%;
  height: 100vh;
  /*border-left: 1px solid black;*/
}


.text-style{
  color: #FFB52E !important;
}
</style>
