import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/authentication/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Refunds from '../views/Refunds.vue'
import Completed from '../views/Completed.vue'
import Settings from "../views/Settings.vue";
import ForgotPassword_Recover from "../views/authentication/ForgotPassword_Recover";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta : {
      auth : true,
      authRedirect: { path: "/login" },
    }
  },
  {
    path: '/refunds',
    name: 'Refunds',
    component: Refunds,
    meta : {
      auth : true,
      authRedirect: { path: "/login" },
    }
  },
  {
    path: '/completed',
    name: 'Completed',
    component: Completed,
    meta : {
      auth : true,
      authRedirect: { path: "/login" },
    }
  },

  {
    path: '/settings',
    name: 'Setting',
    component: Settings,
    meta : {
      auth : true,
      authRedirect: { path: "/login" },
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      guest: true
    }
  } ,
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta : {
      auth : true
    }
  },
  {
    path: '/change_password',
    name: 'change_password',
    component: ForgotPassword_Recover
  },

]

const router = new VueRouter({
  routes,
  mode : "history"
})

export default router
