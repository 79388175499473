<template>

  <div class="center_80">

    <div class="left_30">
      <h3 class="text-center">Settings</h3>

      <br>

      <div class="check_boxes">
        <b-form-checkbox
            id="checkbox-1"
            v-model="settings_filter"
            name="automation"
            value="automation"
            unchecked-value=""
        >
          Automation
        </b-form-checkbox>

      </div>




    </div>
    <div class="right_70">
      <h3 class="text-center">Manage Settings</h3>

      <div class="refund_container">

        <b-form-checkbox v-model="automated_bot" name="check-button" switch size="lg" @change="change_bot_status">
          Automatic refunds
        </b-form-checkbox>

      </div>
    </div>
  </div>

</template>


<script>

import helperMixins from "../mixins/helperMixins";
import {mapGetters} from "vuex";
import axios from "axios";
import {variables} from "../constants/systemConstants";
export default {
  name: "Setting",

  watch: {

  },
  mixins : [helperMixins],
  data () {
    return {
      settings_filter : 'automation',
      automated_bot : true,
      automated_setting : null,
      loading : false,
      all_settings : [],
    }
  },

  beforeMount() {
    this.getUser.role !== "admin" ? this.$router.back() : ''
  },

  mounted() {
    this.load_settings()
  },
  computed: {
    ...mapGetters(['getUser'])
  },

  methods: {

    load_settings(){

      let app = this
      app.loading = true

      let url = "/auth/get_setting"

      axios
          .get(url)
          .then((res) => {
            let all_settings = res.data.all_settings
            app.all_settings = all_settings

            for(let i = 0; i < all_settings.length; i++){
              if(all_settings[i].setting_title === "automatic_refund"){
                app.automated_bot = all_settings[i].setting_value === "true"
                app.automated_setting = all_settings[i]
              }
            }

            app.loading = false

          })
          .catch(() => {
            app.loading = false
            app.makeToast(
                variables.danger,
                "Unable to load settings, please contact Hassan",
                variables.danger
            );
          });
    },

    change_bot_status() {
      let url = "/auth/edit_setting";
      let app = this


      if (this.automated_setting && this.automated_setting._id) {
        app.loading = true
        axios
            .post(url, {
              setting_title: "automatic_refund",
              setting_value: this.automated_bot ? "true" : "false",
              _id: this.automated_setting._id
            })
            .then(() => {

              app.makeToast(
                  variables.success,
                  "Settings updated",
                  variables.success
              );

            })
            .catch(() => {
              app.loading = false
              app.makeToast(
                  variables.danger,
                  "Unable to attend your request, please contact Hassan",
                  variables.danger
              );
            });
      }
      else{
        app.makeToast(
            variables.warning,
            "Please load settings first",
            variables.warning
        );
      }
    }
  }
}
</script>

<style scoped>
input {
  outline:none;
}

.refund_container{
  display: flex;
  flex-direction: column;
}


.center_80{
  margin: auto;
  width: 80%;
  margin-top: 1%;
  display: flex;
  flex-direction: row;

}

.left_30{
  padding: 2%;
  width: 30%;
  border-right: 1px solid black;
}

.check_boxes{
  display: flex;
  flex-direction: column;
  width: 80%;
}
.right_70 {
  padding: 2%;
  width: 70%;
  height: 100vh;
  /*border-left: 1px solid black;*/
}


.text-style{
  color: #FFB52E !important;
}
</style>
