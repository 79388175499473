<template>
  <div id="app" class="main_app">

    <template>
      <template v-if="!(this.$route.name ==='login' || this.$route.name ==='signup' || this.$route.name ==='Forgotpassword' || this.$route.name ==='change_password')">
        <top-nav></top-nav>
      </template>
    </template>


    <div class="app-body " v-if="$auth.ready() && !loading" >
      <router-view />
    </div>

    <div  class="center_">
      <fade-loader v-if="!$auth.ready() || loading" color="#FFB52E" size="19px" style="text-align: center"/>
    </div>

  </div>
</template>



<script>

import helperMixins from "./mixins/helperMixins";
import {mapActions, mapGetters} from "vuex";
import TopNav from "./components/TopNav";

export default {
  name: "App",

  mixins : [helperMixins],
  data () {
    return {
      data : '',
      loading : false
    }
  },
  components: {
    TopNav
  },

  created() {


    let app = this
    app.loading = true
    app.$auth.ready(function () {
      if (app.$auth.check()) {
        this.$auth.fetch().then(function (res) {
          app.loading = false
          app.changeUser(res.data)

        })
      }
      else{
        app.loading = false
      }
    })
  },

  computed: {
    ...mapGetters(['getUser'])
  },
  methods: {
    ...mapActions(['changeUser']),

  }
}
</script>

<style lang="less">
@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap-vue/dist/bootstrap-vue.min.css';
@import '~font-awesome/css/font-awesome.css';
@import "./assets/less/main.less";


</style>

