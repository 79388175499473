<template>
<div></div>

</template>

<script>

import helperMixins from "../mixins/helperMixins";
import {mapGetters} from "vuex";
export default {
  name: "Dashboard",

  watch: {

  },
  mixins : [helperMixins],
  data () {
   return {}
  },

  beforeMount() {

    this.$router.push('/refunds')

  },

  mounted() {
  },
  computed: {
    ...mapGetters(['getUser'])
  },

  methods: {



  }
}
</script>

